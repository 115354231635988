import logo from './logo.svg';
import './App.css';


import {
  BrowserRouter ,
  Routes,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom";
import Homepage from './Components/Home/Home';
import KhalidPage from './Components/Khalid/Khalid';
import MuPage from './Components/Muha/Mu';
import Salmapage from './Components/Salma/Salma';
import YasPage from './Components/Yasmin/Yasmin';
import MaisahPage from './Components/Maisah/Maisah';
import AbdulPage from './Components/Abdulaziz/Abdulaziz';
import AmerPage from './Components/Amer/Amer';
import AminPage from './Components/Amin/Amin';
import SolPage from './Components/Solomon/Solomon';

function App() {
  return (
    <div className="App">
     <BrowserRouter>

       <Routes>

            <Route path='/' element={<Homepage/>} exact />
         
            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis8335OG"} element={<KhalidPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis84877T"} element={<SolPage />} >

              </Route>
            </Route>

            
      
            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis19246"} element={<MuPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis69034P"} element={<Salmapage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis86615N"} element={<YasPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis87106H"} element={<MaisahPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis31913Q"} element={<AbdulPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis76495B"} element={<AmerPage />} >

              </Route>
            </Route>

            <Route path={"/public-resources"}>
              
              <Route path={"/public-resources/finding-a-lawyer-or-paralega/directory-search/membermembernumberis89376N"} element={<AminPage />} >

              </Route>
            </Route>
          
           
       </Routes>

     </BrowserRouter>
          
    </div>
  );
}

export default App;
