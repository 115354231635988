import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Homepage = () => {
  return (
    <div className="container">
      <header className="header">
        <nav className="navbar">
          <Link to="/" className="logo">ISO Explorer</Link>
          <div className="nav-links">
            <Link to="/about">About</Link>
            <Link to="/guide">Guide</Link>
            <Link to="/contact">Contact</Link>
          </div>
        </nav>
      </header>

      <main>
        <section className="hero">
          <h1 className="hero-title">Discover the World of ISO Files</h1>
          <p className="hero-description">Your ultimate guide to understanding and utilizing ISO files for data storage and distribution.</p>
        </section>

        <section className="iso-introduction">
          <h2 id='whatisiso'>ما هو ملف ISO؟</h2>
          <h3 id='whatisiso-2'>:تعريف</h3>
          <p>يعود مصطلح "ملف ISO" أو "صورة ISO" إلى التنسيق الموحد ISO 9660 أو 13346 لوسائط الأقراص المضغوطة ويرمز إلى صورة التخزين المتطابقة للوسائط البصرية. يحتوي ملف ISO على جميع البيانات نفسها التي ستنقلها عند نسخ البيانات إلى قرص مضغوط، DVD، أو Blu-ray. على عكس ملفات الأرشيف بتنسيق ZIP أو RAR، لا يتم ضغط النسخة الثنائية ولكنها تماما نفس النسخة الأصلية من حيث حجم التخزين والبنية والأذونات والبيانات الوصفية.</p>
        </section>

        <section className="features">
          <h2>Features & Benefits of ISO Files</h2>
          <ul className="features-list">
            <li>Universal compatibility across platforms and operating systems.</li>
            <li>Perfect replica of the original data, ensuring integrity and security.</li>
            <li>Convenient for archiving and sharing large amounts of data.</li>
            <li>Easy to create, mount, and burn with common software tools.</li>
          </ul>
        </section>
      </main>

      <footer className="footer">
        <p>© 2024 ISO Explorer. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default Homepage;
